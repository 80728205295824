
export default {
  name: 'BootstrapNavbarBrand',
  props: {
    linkClasses: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: [String, Object],
      required: false,
      default: undefined,
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    goTo() {
      // console.log("Href:" + this.href)
      if (this.href !== '#') window.open(this.href, '_self')
    },
  },
}
