import { render, staticRenderFns } from "./BootstrapNavbarBrand.vue?vue&type=template&id=0bba2dc2"
import script from "./BootstrapNavbarBrand.vue?vue&type=script&lang=js"
export * from "./BootstrapNavbarBrand.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternalLink: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/InternalLink.vue').default})
