export const BootstrapIcon = () => import('../../components/BootstrapIcon.vue' /* webpackChunkName: "components/bootstrap-icon" */).then(c => wrapFunctional(c.default || c))
export const BootstrapNavItem = () => import('../../components/BootstrapNavItem.vue' /* webpackChunkName: "components/bootstrap-nav-item" */).then(c => wrapFunctional(c.default || c))
export const BootstrapNavbar = () => import('../../components/BootstrapNavbar.vue' /* webpackChunkName: "components/bootstrap-navbar" */).then(c => wrapFunctional(c.default || c))
export const BootstrapNavbarBrand = () => import('../../components/BootstrapNavbarBrand.vue' /* webpackChunkName: "components/bootstrap-navbar-brand" */).then(c => wrapFunctional(c.default || c))
export const BootstrapNavbarNav = () => import('../../components/BootstrapNavbarNav.vue' /* webpackChunkName: "components/bootstrap-navbar-nav" */).then(c => wrapFunctional(c.default || c))
export const ExternalLink = () => import('../../components/ExternalLink.vue' /* webpackChunkName: "components/external-link" */).then(c => wrapFunctional(c.default || c))
export const ImgShieldsIoBadge = () => import('../../components/ImgShieldsIoBadge.vue' /* webpackChunkName: "components/img-shields-io-badge" */).then(c => wrapFunctional(c.default || c))
export const InternalLink = () => import('../../components/InternalLink.vue' /* webpackChunkName: "components/internal-link" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PrintCert = () => import('../../components/PrintCert.vue' /* webpackChunkName: "components/print-cert" */).then(c => wrapFunctional(c.default || c))
export const StickyFooter = () => import('../../components/StickyFooter.vue' /* webpackChunkName: "components/sticky-footer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
