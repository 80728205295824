import { render, staticRenderFns } from "./default.vue?vue&type=template&id=262df9b6"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=262df9b6&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/Logo.vue').default,BootstrapNavbarBrand: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/BootstrapNavbarBrand.vue').default,BootstrapNavItem: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/BootstrapNavItem.vue').default,BootstrapIcon: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/BootstrapIcon.vue').default,BootstrapNavbarNav: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/BootstrapNavbarNav.vue').default,BootstrapNavbar: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/BootstrapNavbar.vue').default,ExternalLink: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/ExternalLink.vue').default,StickyFooter: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/StickyFooter.vue').default})
