
export default {
  name: 'BootstrapNavbar',
  props: {
    toggleable: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
}
