
export default {
  name: 'BootstrapNavItem',
  props: {
    linkClasses: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: [String, Object],
      required: false,
      default: undefined,
    },
    linkAttrs: {
      type: Object,
      required: false,
      default: () => {
        return { title: '' }
      },
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    goTo() {
      // console.log("Href:" + this.href)
      if (this.href !== '#') window.open(this.href, '_self')
    },
  },
}
