
export default {
  name: 'DefaultLayout',
  data() {
    return {
      isCookiesAccepted: true,
      modeSwitcher: false,
      // platforms: {
      // internal: [
      // {
      //   link: '/ciss/',
      //   name: 'Akademia Bezpieczeństwa IT',
      // },
      // {
      //   link: '/educativo/',
      //   name: 'Educativo',
      // },
      // {
      //   link: '/kuvi/',
      //   name: 'Kuvi',
      // },
      // {
      //   link: '/videopoint/',
      //   name: 'Videopoint',
      // },
      // {
      //   link: '/devmentors/',
      //   name: 'Devmentors',
      // },
      // {
      //   link: '/akademiadotnet/',
      //   name: 'Akademia .NET',
      // },
      // ],
      // external: [
      //   {
      //     link: 'https://eduj.pl/p/profil/Paweł-TW5FUmpYdWUvNi9WY1lnanB4TlgyQT09',
      //     name: 'Eduj',
      //   },
      //   {
      //     link: 'https://eduweb.pl/profil/77103',
      //     name: 'Eduweb',
      //   },
      //   {
      //     link: 'https://platforma.strefakursow.pl/p/profil/Pawe%C5%82-cVkweVo2cXEzN2tuWm5vb0xtb1BNdz09',
      //     name: 'Strefa Kursów',
      //   },
      // ],
      // },
      socials: [
        {
          link: 'https://www.facebook.com/profile.php?id=100002685182268',
          name: 'Facebook',
        },
        {
          link: 'https://twitter.com/CyberZiom',
          name: 'Twitter',
        },
        {
          link: 'https://pl.linkedin.com/in/pawe%C5%82-wilczek-885803151',
          name: 'LinkedIn',
        },
        {
          link: 'https://www.instagram.com/spring.java.nuxt.js.developer/?hl=pl',
          name: 'Instagram',
        },
        {
          link: 'https://github.com/beowoolf/',
          name: 'GitHub',
        },
      ],
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
  async mounted() {
    const workbox = await window.$workbox
    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        // If we don't do this we'll be displaying the notification after the initial installation, which isn't perferred.
        if (event.isUpdate) {
          // whatever logic you want to use to notify the user that they need to refresh the page.
          console.log('Update was applied. :-D')
          // this.$bvToast.show('update-toast')
        }
      })
    }
    this.isCookiesAccepted = this.$cookies.get('isCookiesAccepted')
    // const dropdowns = document.getElementsByClassName('dropdown-toggle')
    // for (let index = 0; index < dropdowns.length; index++) {
    //   const element = dropdowns[index]
    //   for (let i = 0; i < element.children.length; i++) {
    //     const child = element.children[i]
    //     element.setAttribute('title', child.textContent)
    //   }
    // }
  },
  methods: {
    acceptCookies() {
      this.$cookies.set('isCookiesAccepted', true, {
        maxAge: 60 * 60 * 24 * 366 * 10,
      })
      this.isCookiesAccepted = true
    },
    // closeUpdateToast(reload) {
    //   this.$bvToast.hide('update-toast')
    //   if (reload === true) window.location.reload(true)
    // },
    setColorModeTheme(color) {
      // console.log(color)
      this.$colorMode.preference = color
      this.$cookies.set('colorModeTheme', color, {
        maxAge: 60 * 60 * 24 * 366,
      })
    },
  },
}
